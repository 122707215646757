export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const createGradientColor = (context, appInfo, stops = 2) => {
  const org = appInfo.org;
  const canvasWidth = context.chart.width;
  const canvasHeight = context.chart.height;
  const gradient = context.chart.ctx.createLinearGradient(0, 0, 300, 0);
  stops = 100;
  const stopPercentage = 1 / (stops - 1);
  for (let i = 0; i < stops; i++) {
    const stopColor =
      i === stops - 1
        ? org.color2
        : interpolateColor(org.color1, org.color2, i / (stops - 1));
    if (i % 10 === 0) {
      gradient.addColorStop(i * stopPercentage, stopColor);
    }
  }

  return gradient;
};

function interpolateColor(color1, color2, t) {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const r = Math.round(rgb1.r + (rgb2.r - rgb1.r) * t);
  const g = Math.round(rgb1.g + (rgb2.g - rgb1.g) * t);
  const b = Math.round(rgb1.b + (rgb2.b - rgb1.b) * t);

  return `rgb(${r},${g},${b})`;
}

function hexToRgb(hex) {
  if (hex.startsWith("#")) {
    hex = hex.substring(1);
    return {
      r: parseInt(hex.substring(0, 2), 16),
      g: parseInt(hex.substring(2, 4), 16),
      b: parseInt(hex.substring(4, 6), 16),
    };
  } else if (hex.startsWith("rgb(")) {
    const values = hex.substring(4, hex.length - 1).split(",");
    return {
      r: parseInt(values[0].trim()),
      g: parseInt(values[1].trim()),
      b: parseInt(values[2].trim()),
    };
  } else {
    throw new Error("Invalid input format");
  }
}

export const hexToRgbString = (hex) => {
  hex = hex.replace(/^#/, "");

  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  return `rgb(${r}, ${g}, ${b})`;
};

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

// const API_ENDPOINT = process.env.API_ENDPOINT || "http://localhost:3001/api/v1";
const API_ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT || "http://localhost:3001/api/v1";

console.log("API_ENDPOINT", API_ENDPOINT);

export const getData = async (endpoint, token) => {
  const response = await fetch(`${API_ENDPOINT}/${endpoint}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Expose-Headers": "*",
    },
  });
  if (response.status === 401 || response.status === 403) {
    localStorage.removeItem("appContext");

    window.location.href = "/";
    return;
  }
  // localStorage.removeItem('appContext');

  if (!response.ok) {
    // error handle: todo
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const updateData = async (endpoint, data, token) => {
  const response = await fetch(`${API_ENDPOINT}/${endpoint}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Expose-Headers": "*",
    },
    body: data,
  });
  if (response.status === 401 || response.status === 403) {
    localStorage.removeItem("appContext");

    window.location.href = "/";
    return;
  }
  if (!response.ok) {
    let errorMsg = "Network response was not ok";
    try {
      const errorResponse = await response.json();
      errorMsg = errorResponse.error || errorMsg;
    } catch (error) {
      console.error("Error parsing error response:", error);
    }
    throw new Error(errorMsg);
  }
  return response;
};

export const addData = async (endpoint, data, token, isMultipart = false) => {
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);

  if (!isMultipart) {
    headers.append("Content-Type", "application/json");
  }

  const response = await fetch(`${API_ENDPOINT}/${endpoint}`, {
    method: "POST",
    headers: headers,
    body: data,
  });
  if (response.status === 401 || response.status === 403) {
    localStorage.removeItem("appContext");

    window.location.href = "/";
    return;
  }
  if (!response.ok) {
    let errorMsg = "Network response was not ok";
    try {
      const errorResponse = await response.json();
      errorMsg = errorResponse.error || errorMsg;
    } catch (error) {
      console.error("Error parsing error response:", error);
    }
    throw new Error(errorMsg);
  }
  const text = await response.text();
  if (text) {
    return JSON.parse(text);
  }

  return response;
};

export const deleteData = async (endpoint, token) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/${endpoint}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Expose-Headers": "*",
      },
    });

    if (response.status === 401 || response.status === 403) {
      localStorage.removeItem("appContext");
      window.location.href = "/";
      return;
    }

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    //if the response has content, parsing it as JSON
    if (response.status !== 204) {
      return response.json();
    } else {
      // for no Content
      return { success: true };
    }
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};