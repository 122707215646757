import React, { useState, useContext } from "react";
import {
  EmailAuthProvider,
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
} from "firebase/auth";
import Alert from "./Alert";
import { AppContext } from "../../App";
import { updateData } from "../../common/utils";

function ResetPassword({ closeModal }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [buttonText, setButtonText] = useState("Reset Password");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("green");
  const [isLoading, setIsLoading] = useState(false);
  const { appInfo } = useContext(AppContext);

  const updateUserDetails = async (uid, emailToken, password, token) => {
    try {
      const body = JSON.stringify({
        emailToken: emailToken,
        password: password,
      });
      await updateData(`users/${uid}`, body, token);
      console.log("password reset success!");
    } catch (error) {
      console.log("Error updating password");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, oldPassword);
    try {
      await reauthenticateWithCredential(user, credential);
    } catch (error) {
      setAlertColor("red");
      setAlertMessage(
        "Invalid old password. Please enter the correct old password."
      );
      setShowSuccessAlert(true);
      setIsLoading(false);
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setAlertMessage("New password and confirm password do not match.");
      setAlertColor("red");
      setShowSuccessAlert(true);
      setIsLoading(false);
      return;
    }
    try {
      await updatePassword(auth.currentUser, newPassword);
      await updateUserDetails(
        appInfo.userInfo.u_id,
        appInfo.userInfo.email_token,
        newPassword,
        appInfo.token
      );
      setButtonText("OK");
      setAlertMessage("user password updated successfully");
      setShowSuccessAlert(true);
      setIsLoading(false);
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      setShowSuccessAlert(false);
      setAlertMessage("Error updating user password");
      setAlertColor("red");
      setIsLoading(false);
      console.log("Error updating user password: ", error.code, error.message);
    }
  };

  const handleOldPassword = (event) => setOldPassword(event.target.value);
  const handleNewPassword = (event) => setNewPassword(event.target.value);
  const handleConfirmNewPassword = (event) =>
    setConfirmNewPassword(event.target.value);

  const handleModalClose = () => {
    if (buttonText === "OK") {
      closeModal();
    }
  };

  return (
    <>
      {showSuccessAlert && (
        <Alert
          message={alertMessage}
          timeout={3000}
          color={alertColor}
          onClose={() => setShowSuccessAlert(false)}
        />
      )}
      <form onSubmit={handleSubmit}>
        <div className="grid gap-6">
          <div className="grid grid-cols-3 items-center gap-8">
            <label
              htmlFor="oldPassword"
              className="col-span-1 text-left block text-sm font-medium text-gray-900"
            >
              Old Password
            </label>
            <input
              type="password"
              id="oldPassword"
              value={oldPassword}
              onChange={handleOldPassword}
              className="col-span-2 text-sm font-medium bg-gray-300 w-full"
              required
            />
          </div>
          <div className="grid grid-cols-3 items-center gap-8">
            <label
              htmlFor="newPassword"
              className="col-span-1 text-left block text-sm font-medium text-gray-900"
            >
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={handleNewPassword}
              className="col-span-2 text-sm font-medium bg-gray-300 w-full"
              required
            />
          </div>
          <div className="grid grid-cols-3 items-center gap-8">
            <label
              htmlFor="confirmNewPassword"
              className="col-span-1 text-left block text-sm font-medium text-gray-900"
            >
              Confirm New Password
            </label>
            <input
              type="password"
              id="confirmNewPassword"
              value={confirmNewPassword}
              onChange={handleConfirmNewPassword}
              className="col-span-2 text-sm font-medium bg-gray-300 w-full"
              required
            />
          </div>
          <div className="flex items-center justify-center gap-8">
            <button
              type="submit"
              onClick={handleModalClose}
              className={`border-none ${
                isLoading
                  ? "text-black bg-gray-400"
                  : "text-white bg-purple-900 hover:bg-red-500"
              } rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6`}
              disabled={isLoading}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default ResetPassword;
