import { React, useContext, useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../App";
import { addData, getData, updateData } from "../common/utils";
import Alert from "../modules/Settings/Alert";
import logo from "./../assets/logo.png";
import bg from "./../assets/bg.jpg";
import bgh from "./../assets/brand-logo-horizontal.png";
import { hexToRgbString } from "./../common/utils";

import ForgotPassword from "./forgotPassword";
import VerifyEmail from "./verifyEmail";
import { setUserData } from "./authenticateService";
function calculateLuminance(rgb) {
  const r = rgb[0] / 255;
  const g = rgb[1] / 255;
  const b = rgb[2] / 255;

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

function isLightColor(rgb) {
  const luminance = calculateLuminance(rgb);
  const threshold = 0.5;
  return luminance > threshold;
}

// TODO: The only people who should be able to sign into the site are
// gym owners and their staff
// Currently users are able to sign in as well. This needs to be prevented.
//
// TODO: Implement password recovery

function SignIn() {
  const { appInfo, setAppInfo } = useContext(AppContext);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("green");
  const [alertTimeout, setAlertTimeout] = useState(3000);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [isVerifyMail, setIsVerifyMail] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname.includes("resetPassword")) {
      setIsReset(true);
      setIsVerifyMail(false);
    } else if (window.location.pathname.includes("verifyEmail")) {
      setIsReset(false);
      setIsVerifyMail(true);
    } else {
      setIsReset(false);
      setIsVerifyMail(false);
    }
  }, []);

  const fetchOrg = async (orgId, token) => {
    try {
      const orgs = await getData(`orgs/${orgId}`, token);
      if (!orgs.color1 || !orgs.color2) {
        if (!orgs.color1) {
          orgs.color1 = "#fe5a50";
        }
        if (!orgs.color2) {
          orgs.color2 = "#5b0069";
        }
      }

      var rgb1 = hexToRgbString(orgs.color1);
      var rgb2 = hexToRgbString(orgs.color2);
      var colors1 = rgb1.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      var colors2 = rgb2.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      var brightnessAdjustment = 0.5;
      const isLightColor1 = isLightColor([colors1[1], colors1[2], colors1[3]]);
      const isLightColor2 = isLightColor([colors2[1], colors2[2], colors2[3]]);
      if (isLightColor1) {
        orgs.color1 = `rgb(${Math.floor(
          colors1[1] * brightnessAdjustment
        )},${Math.floor(colors1[2] * brightnessAdjustment)},${Math.floor(
          colors1[3] * brightnessAdjustment
        )})`;
      }

      if (isLightColor2) {
        orgs.color2 = `rgb(${Math.floor(
          colors2[1] * brightnessAdjustment
        )},${Math.floor(colors2[2] * brightnessAdjustment)},${Math.floor(
          colors2[3] * brightnessAdjustment
        )})`;
      }

      orgs.tc1 = isLightColor1 ? "rgb(0, 0, 0, 1)" : "rgb(255, 255, 255, 1)";
      orgs.tc2 = isLightColor2 ? "rgb(0, 0, 0, 1)" : "rgb(255, 255, 255, 1)";
      return orgs;
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  const fetchUser = async (uid, token) => {
    try {
      const users = await getData(`users?userId=${uid}`, token);
      return users[0];
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  const updateUserStatus = async (uid, email, token) => {
    try {
      const body = JSON.stringify({ status: 1, syncEmail: email });
      await updateData(`users/${uid}`, body, token);
      console.log("Status updated successfully");
    } catch (error) {
      console.error("Error updating users status: ", error);
    }
  };

  const sendEmailVerification = async (uid, token) => {
    try {
      const user = await fetchUser(uid, token);
      const org = await fetchOrg(user.org_id, token);
      const webHost = process.env.REACT_APP_WEB_URL;
      let body = {
        templateName: "email_verification.hbs",
        data: {
          user: user.name,
          organizationName: org.name,
          redirectionUrl: `${webHost}verifyEmail?emailToken=${user.email_token}`,
        },
        subject: "Verify your email",
        toEmail: user.email,
      };
      body = JSON.stringify(body);
      await addData("emails/triggerEmail", body, token);
      console.log("Email resent!");
    } catch (error) {
      console.error("Error sending email", error);
    }
  };

  function HandleSignInRequest(event) {
    //debugger;
    event.preventDefault();
    const email = document.getElementById("email_in").value;
    const password = document.getElementById("password_in").value;
    const auth = getAuth();
    document.cookie = `email=${email}`;

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const { user } = userCredential;

        const token = user && (await user.getIdToken());
        if (user.emailVerified) {
          if (user.uid && appInfo.org == null) {
            try {
              await fetchUser(user.uid, token).then(async (userInfo) => {
                console.log("User Info: ", userInfo);
                if (userInfo.status === 3) {
                  setAlertMessage(
                    "Your account has been deactivated. Please reach out to admin team."
                  );
                  setAlertColor("yellow");
                  setShowSuccessAlert(true);
                  setAlertTimeout(5000);
                  return;
                }
                await fetchOrg(userInfo.org_id, token).then(async (result) => {
                  let redprintInfo =
                    (userInfo?.role_id ?? 0) == 1 ? userInfo : null;
                  if (userInfo.status === 2 || user.email !== userInfo.email) {
                    await updateUserStatus(
                      userInfo.u_id,
                      user.email !== userInfo.email ? user.email : null,
                      token
                    );
                    let Userdata = {
                      user: user.uid,
                      token: token,
                      org: result,
                      loggedIn: true,
                      userInfo: userInfo,
                      redprint: redprintInfo,
                    };
                    setAppInfo(Userdata);
                    setUserData(Userdata);
                    if (userInfo.role_id === 1) {
                      navigate("/settings");
                    } else if (userInfo.role_id === 4) {
                      navigate("/videos");
                    } else {
                      navigate("/analytics");
                    }
                  } else {
                    console.log("Successfully Signed In");
                    let Userdata = {
                      user: user.uid,
                      token: token,
                      org: result,
                      loggedIn: true,
                      userInfo: userInfo,
                      redprint: redprintInfo,
                    };

                    setAppInfo(Userdata);
                    setUserData(Userdata);
                    if (userInfo.role_id === 1) {
                      navigate("/settings");
                    } else if (userInfo.role_id === 4) {
                      navigate("/videos");
                    } else {
                      navigate("/analytics");
                    }
                  }
                });
              });
            } catch (error) {
              console.error("Error fetching org:", error);
            }
          } else {
            if (
              appInfo.userInfo.role_id === 1 ||
              appInfo.userInfo.role_id === 4
            ) {
              navigate("/settings");
            } else {
              navigate("/analytics");
            }
          }
        } else {
          console.log("Please verify email before signing in");
          setAlertMessage(
            "Please verify email before signing in. We have resent new Email for verification"
          );
          setAlertColor("yellow");
          setShowSuccessAlert(true);
          await sendEmailVerification(user.uid, token);
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setAlertMessage(errorMessage);
        setAlertColor("red");
        setShowSuccessAlert(true);
        console.log("signInWithEmailAndPassword catch");
        console.log(errorCode);
        console.log(errorMessage);
      });
  }

  const passwordRecover = (event) => {
    event.preventDefault();
    setIsReset(true);
    navigate("/resetPassword");
  };

  return (
    <>
      <div
        className="flex  flex-col justify-center items-center min-h-screen bg-gray-200"
        style={{
          height: "100vh",
          width: "100%",
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {showSuccessAlert && alertColor === "red" && (
          <div
            className=" flex border-2 bg-white  border-red-600 m-2 justify-center items-center text-center "
            style={{
              boxShadow: "0 0 0 4px #fcf4f4 inset",
              width: "30rem",
              height: "5rem",
            }}
          >
            <h1 className="font-semibold">
              Wrong password or email. Please enter the details again.
            </h1>
          </div>
        )}

        {showSuccessAlert && alertColor === "yellow" && (
          <>
            <Alert
              message={alertMessage}
              timeout={alertTimeout}
              color={alertColor}
              onClose={() => setShowSuccessAlert(false)}
            />
          </>
        )}

        <div
          className="flex items-center space-x-2"
          style={{
            position: "absolute",
            marginTop: "-42rem",
            marginLeft: "-81rem",
          }}
        >
          <img
            src={bgh}
            alt="Redprint logo"
            className="w-40 fixed top-6 left-4"
          />
        </div>
        {isReset ? (
          <div className="mx-auto max-w-screen-lg bg-white rounded-lg shadow-lg overflow-hidden">
            <ForgotPassword />
          </div>
        ) : isVerifyMail ? (
          <div className="mx-auto max-w-screen-lg bg-white rounded-lg shadow-lg overflow-hidden">
            <VerifyEmail />
          </div>
        ) : (
          <div className="mx-auto max-w-screen-lg bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="flex flex-row items-center mb-4 justify-between">
              <span className="text-2xl text-gray-400 pl-8 pt-1">Login</span>
              <img src={logo} alt="Redprint logo" className="w-8 mr-8 mt-6" />
            </div>
            <form
              action="#"
              className="pl-8 pr-8 pb-4"
              style={{
                width: "30rem",
                backgroundColor: "#ffffff",
                display: "flex",
                // alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: "18rem",
              }}
            >
              <span className="text-base text-left">Email address</span>
              <input
                type="email"
                id="email_in"
                // placeholder="Email address"
                className="bg-gray-200 border-b-2 border-purple-500 rounded py-3 px-4 my-2 w-full text-gray-500"
              />
              <span className="text-base text-left">Password</span>

              <input
                type="password"
                id="password_in"
                // placeholder="Password"
                className="bg-gray-200 border-b-2 border-purple-500 rounded py-3 px-4 my-2 w-full text-gray-500"
              />

              <button
                onClick={HandleSignInRequest}
                className="bg-red-600 text-white font-bold px-4 py-2 mt-2 rounded shadow hover:bg-[#4a0057] transition-colors duration-300 ease-in-out"
              >
                Login
              </button>
              <div className="pl-32 pt-4">
                <a href="#" onClick={passwordRecover} className="text-blue-600">
                  Forgot my password
                </a>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default SignIn;
