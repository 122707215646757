import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
//import { toast } from "react-toastify";
import Header from "../../common/header";
import Table from "../../common/table";
import Button from "./button";
import SearchBar from "../../common/searchBar";
import Pagination from "./pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faUserPlus,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import AddAccessForm from "./form";
import SignOut from "../../authentication/signOut";
import { getData, addData, updateData, deleteData } from "../../common/utils";
import PendingRequestsTable from "./PendingRequestsTable";
import Modal from "../../common/modal";
import ResetPassword from "./resetPassword";
import Alert from "./Alert";

// Settings Component
function SettingsPage({ user, onOrgClick }) {
  const [showAddClientForm, setShowAddClientForm] = useState(false);
  const [orgs, setAdmins] = useState([]);
  const [showAddAccessForm, setShowAddAccessForm] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 3;
  const [searchTerm, setSearchTerm] = useState("");
  //const [showApproveConfirmation, setShowApproveConfirmation] = useState(false);
  const [showDeclineConfirmation, setShowDeclineConfirmation] = useState(false);
  const [isAddingAdmin, setIsAddingAdmin] = useState(false);
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);
  const { appInfo, setAppInfo } = useContext(AppContext);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [selectedOrgName, setSelectedOrgName] = useState("");
  const [isResetPasswordVisible, setIsResetPasswordVisible] = useState(false);

  const handleClick = (orgId) => {
    onOrgClick(orgId);
    setSelectedOrgId(orgId);
  };
  const fetchAdmins = async () => {
    try {
      const adminsResponse = await getData("orgs/admins", appInfo.token);
      console.log("adRes", adminsResponse);
      setAdmins(adminsResponse);
      return adminsResponse;
    } catch (error) {
      console.error("Error fetching orgs:", error);
      setError("Error fetching orgs. Please try again later.");
    }
  };
  const fetchRequests = async () => {
    try {
      const pendingRequests = await getData("orgs/requests", appInfo.token);
      setRequests(pendingRequests);
      return pendingRequests;
    } catch (error) {
      console.error("Error fetching requests:", error);
      setError("Error fetching requests. Please try again later.");
    }
  };

  const fetchData = async () => {
    try {
      const adminsResponse = await fetchAdmins();
      const pendingRequests = await fetchRequests();
      setAdmins(adminsResponse);
      setRequests(pendingRequests);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred. Please try again later.");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (orgId) => {
    console.log("Deleting organization with ID:", orgId); // Log orgId value
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this organization?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      const body = JSON.stringify({
        status: 3,
      });
      await updateData(`orgs/${orgId}`, body, appInfo.token); // Call deleteData function with the organization ID
      // Optionally, you can refresh the data after deletion
      fetchData();
    } catch (error) {
      console.error("Error deleting organization:", error);
      setError("Error deleting organization. Please try again later.");
    }
  };

  const handleApprove = async (gymId, isApproved, orgId, token) => {
    try {
      await updateGymDetails(gymId, isApproved, orgId);
      setShowAddClientForm(false);
      setSuccessMessage(
        "Gym request is" + isApproved
          ? "Success: Request is approved"
          : "declined"
      );
      setShowSuccessToast(true);
      setTimeout(() => {
        setShowSuccessToast(false);
      }, 2000);
      setTimeout(() => {
        window.location.reload();
      }, 2500);

      const approvedRequest = requests.find(
        (request) => request.gym_id === gymId
      );
      if (!approvedRequest) {
        console.log("Declined request not found.");
        return;
      }
      const adminEmails = await fetchAdminEmails(
        approvedRequest.org_name,
        token
      );
      //console.log("Admin Emails:", adminEmails);

      for (const adminEmail of adminEmails) {
        await sendRequestStatusEmail(
          adminEmail,
          approvedRequest.gym_name,
          "approved",
          token
        );
      }
    } catch (error) {
      console.error("Error handling approval:", error);
      setError(
        "An error occurred while handling approval. Please try again later."
      );
    }
  };

  const fetchAdminEmails = async (orgName, token) => {
    try {
      // Find the organization object with the given name
      const adminsResponses = await fetchAdmins();
      const org = adminsResponses.find((org) => org.name === orgName);
      if (!org) {
        console.error(`Organization "${orgName}" not found.`);
        return [];
      }

      // Extract admin emails from the organization object
      const adminEmails = org.admins.split(",");

      return adminEmails;
    } catch (error) {
      console.error("Error fetching admins:", error);
      setError("Error fetching admins. Please try again later.");
      return [];
    }
  };

  const handleDecline = async (gymId, isApproved, orgId, token) => {
    try {
      await updateGymDetails(gymId, isApproved, orgId);
      setRequests(requests.filter((request) => request.gymId !== gymId));
      setShowSuccessToast(true);
      setSuccessMessage("Success: Request is declined.");
      setTimeout(() => {
        setShowSuccessToast(false);
      }, 2000);
      setTimeout(() => {
        window.location.reload();
      }, 2500);

      const declinedRequest = requests.find(
        (request) => request.gym_id === gymId
      );
      if (!declinedRequest) {
        console.log("Declined request not found.");
        return;
      }

      // Fetch admin emails for the organization using org name
      const adminEmails = await fetchAdminEmails(
        declinedRequest.org_name,
        token
      );
      //console.log("Admin Emails:", adminEmails);

      // Send email notifications or perform other actions with adminEmails
      for (const adminEmail of adminEmails) {
        await sendRequestStatusEmail(
          adminEmail,
          declinedRequest.gym_name,
          "declined",
          token
        );
      }
    } catch (error) {
      console.error("Error declining request:", error);
      setError("Error declining request. Please try again later.");
    }
  };

  const sendRequestStatusEmail = async (
    recipientEmail,
    gymName,
    status,
    token
  ) => {
    try {
      const body = {
        templateName: "requests.hbs",
        data: {
          gymName: gymName,
          status: status,
        },
        subject: `Request Status Update: ${gymName}`,
        toEmail: recipientEmail,
      };
      const response = await addData(
        `/emails/triggerEmail`,
        JSON.stringify(body),
        token
      );

      if (!response.message) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send email");
      }
      // Email sent successfully
      return true;
    } catch (error) {
      console.error("Error sending email:", error);
      // Email sending failed
      return false;
    }
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const toggleAddClientForm = () => {
    setShowAddClientForm(!showAddClientForm);
    setError(null);
  };

  const toggleAddAccessForm = () => {
    setShowAddAccessForm(!showAddAccessForm);
    setIsAddingAdmin(false);
  };

  const toggleAddAdminForm = (orgId, name) => {
    setShowAddAccessForm(!showAddAccessForm);
    setIsAddingAdmin(true);
    setSelectedOrgId(orgId);
    setSelectedOrgName(name);
  };

  const addOrg = async (data) => {
    try {
      const body = {
        name: data.name,
        type: data.type,
        tagline: data.tagline,
      };
      console.log("addOrg:", body);
      const request = JSON.stringify(body);
      console.log("addOrg1:", request);
      const response = await addData("orgs", request, appInfo.token);
      console.log("addOrg2:", response);
      console.log("Organization added successfully");
      return response.org_id;
    } catch (error) {
      console.error("Error adding organization:", error);
      setError("Error adding organization. Please try again.");
      return false;
    }
  };

  const addGym = async (org_id, type) => {
    try {
      const body = {
        gymName: "Gym1",
        type: type,
        roleId: 1,
      };
      const request = JSON.stringify(body);
      const response = await addData(
        `gyms?org_id=${org_id}`,
        request,
        appInfo.token
      );
      console.log("Gym added successfully");
      return response;
    } catch (error) {
      console.log("Error adding Gym:", error);
      return false;
    }
  };

  const addUser = async (data, orgId) => {
    try {
      const body = {
        name: data.user_name,
        email: data.email,
        gymIds: [data.gym_id],
        roleId: data.role_id,
        position: data.position,
        orgId: orgId,
      };
      const request = JSON.stringify(body);
      const response = await addData(`gymUsers`, request, appInfo.token);

      console.log("User access added successfully");
      return response;
      // window.location.reload();
    } catch (error) {
      console.error("Error adding user:", error);
      // Check if the error message indicates that the email is already in use
      const errorMessage = error.message.includes("auth/email-already-in-use")
        ? "Error adding user. Email already exists"
        : "Error adding user. Please try again later.";
      setError(errorMessage);
      return false;
    }
  };

  const updateOrgInfo = async (org_id, user_id) => {
    try {
      const body = {
        owner_id: user_id,
      };
      const request = JSON.stringify(body);
      await updateData(`orgs/${org_id}`, request, appInfo.token);

      console.log("Org Info updated successfully");
    } catch (error) {
      console.log("Error updating org Info", error);
    }
  };

  const triggerEmail = async (data, userInfo) => {
    try {
      const webHost = process.env.REACT_APP_WEB_URL;
      let body = {
        templateName: "email_verification.hbs",
        data: {
          user: data.user_name,
          organizationName: appInfo.org.name,
          redirectionUrl: `${webHost}verifyEmail?emailToken=${userInfo.emailToken}`,
        },
        subject: "Verify your email",
        toEmail: data.email,
      };
      body = JSON.stringify(body);
      const response = await addData(
        `/emails/triggerEmail`,
        body,
        appInfo.token
      );

      if (!response.message) {
        const errorData = await response.JSON();
        throw new Error(errorData.message || "Failed to send email");
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let org_data = {};
    let formData = {};
    let firstName = "";
    let lastName = "";
    let allFieldsFilled = true;

    for (let element of event.target.elements) {
      if (element.name) {
        if (element.name === "firstName") {
          firstName = element.value;
        } else if (element.name === "lastName") {
          lastName = element.value;
        } else if (element.name === "email") {
          formData[element.name] = element.value;
        } else if (element.name === "organization") {
          org_data["name"] = element.value;
        } else if (element.name === "type") {
          org_data["type"] = element.value;
        } else if (element.name === "tagline") {
          org_data["tagline"] = element.value;
        }
      }
    }

    if (!firstName || !lastName || !formData["email"] || !org_data["name"]) {
      allFieldsFilled = false;
    }

    if (!allFieldsFilled) {
      setError("Please fill out all fields.");
      setIsLoading(false);
      return;
    }
    setError(null);

    formData["user_name"] = `${firstName} ${lastName}`;
    formData["role_id"] = 2;
    formData["position"] = "Admin";
    console.log("Form Data", formData);

    try {
      const orgId = await addOrg(org_data);

      if (!orgId) {
        setError("Failed to add organization.");
        setIsLoading(false);
        return;
      }

      const gymId = await addGym(orgId, org_data.type);

      if (!gymId) {
        setError("Failed to add gym.");
        setIsLoading(false);
        return;
      }

      formData["gymIds"] = gymId;

      const userResponse = await addUser(formData, orgId);

      console.log("ur", userResponse);

      if (!userResponse || !userResponse.user.uid) {
        setError("Failed to add user.");
        setIsLoading(false);
        return;
      }

      await updateOrgInfo(orgId, userResponse.user.uid);

      await triggerEmail(formData, userResponse);
      setIsLoading(false);
      setShowAddClientForm(false);
      setSuccessMessage("Success: An invite sent through email");
      setShowSuccessToast(true);

      setTimeout(() => {
        setShowSuccessToast(false);
      }, 2000);

      setTimeout(() => {
        //window.location.reload();
      }, 2500);
    } catch (error) {
      console.error("Error adding user:", error);
      setError("Error adding user.", error);
      setIsLoading(false);
    }
    // try {
    //   await addOrg(org_data).then(async (orgId) => {
    //     await addGym(orgId, org_data.type);
    //     await addUser(formData).then(async (response) => {
    //       formData["u_id"] = response?.u_id;
    //       formData["org_name"] = org_data["name"];

    //       await triggerEmail(formData, response);
    //     });
    //     setShowAddClientForm(false);
    //     setSuccessMessage("Success: An invite sent through email");
    //     setShowSuccessToast(true);
    //     setTimeout(() => {
    //       setShowSuccessToast(false);
    //     }, 2000);
    //     setTimeout(() => {
    //       //window.location.reload();
    //     }, 2500);

    //     fetchData();
    //   });
    // } catch (error) {
    //   console.error("Error adding user:", error);
    //   setError("Error adding user.", error);
    // }
  };

  const toggleResetPasswordVisibility = () => {
    setIsResetPasswordVisible(!isResetPasswordVisible);
  };

  const updateGymDetails = async (gymId, isApproved, orgId) => {
    try {
      const data = { status: isApproved ? 1 : 3 };
      const request = JSON.stringify(data);
      const response = await updateData(
        `gyms/${gymId}?org_id=${orgId}`,
        request,
        appInfo.token
      );

      if (!response.ok) {
        throw new Error(response.message || "Failed to update gym details");
      }

      console.log("Gym details updated successfully");
    } catch (error) {
      console.error("Error updating gym details:", error);
      setError("Error updating gym details. Please try again later.");
      return false;
    }
  };

  const handleLogout = () => {
    SignOut(navigate, setAppInfo);
  };

  const organizationHeaders = [
    "Organization Name",
    "Gyms",
    "Organization Admin",
    "Actions",
  ];

  const organizationValues = orgs.map((org) => [
    org.name,
    org.gyms ?? 0,
    org.admins ?? "",
    <span>
      <Button
        value={<FontAwesomeIcon icon={faUserPlus} />}
        class="text-black-900 border-none bg-gray-300 hover:bg-purple-300 rounded-lg font-medium text-sm text-sm px-1 py-0.5 me-2 mb-2"
        onClick={() => toggleAddAdminForm(org.org_id, org.name)}
      />{" "}
      | &nbsp; &nbsp;
      <Button
        value={<FontAwesomeIcon icon={faTrash} />}
        class="text-black-900 border-none bg-gray-300 hover:bg-red-300 rounded-lg font-medium text-sm text-sm px-1 py-0.5 me-2 mb-2"
        onClick={() => handleDelete(org.org_id)} // This line adds the delete functionality
      />
      | &nbsp; &nbsp;
      <Button
        value={<FontAwesomeIcon icon={faCog} />}
        class="text-black-900 border-none bg-gray-300 hover:bg-red-300 rounded-lg font-medium text-sm text-sm px-1 py-0.5 me-2 mb-2"
        onClick={() => handleClick(org.org_id)}
      />
    </span>,
  ]);

  const filteredValues = organizationValues.filter((row) =>
    row.some((cell) =>
      cell.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  // Calculate total number of pages based on rowsPerPage
  const totalPages = Math.ceil(organizationValues.length / rowsPerPage);

  // Slice organization values based on pagination
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(
    startIndex + rowsPerPage,
    organizationValues.length
  );
  const paginatedValues = filteredValues.slice(startIndex, endIndex);

  const remainingItems = paginatedValues.length % rowsPerPage;
  const buffersNeeded = remainingItems === 0 ? 0 : rowsPerPage - remainingItems;

  for (let i = 0; i < buffersNeeded; i++) {
    paginatedValues.push(["", "", "", ""]);
  }

  return (
    <>
      {isResetPasswordVisible && (
        <Modal toggleModal={toggleResetPasswordVisibility}>
          <ResetPassword />
        </Modal>
      )}
      <div className="space-y-4">
        <div className="flex justify-between mb-3">
          <div className="grid auto-cols-max mt-1">
            <div className="settingsCard bg-white justify-between rounded-lg shadow-md p-4 ">
              <div className="row-1 flex items-center mb-4">
                <Header title="RedPrint" subTitleClass="text-gray-500" />
              </div>
              <div className="row-2 flex items-center mb-4">
                <div className="mr-10">
                  <p className="text-gray-500">Name</p>
                  <p className="font-bold">{user?.name}</p>
                </div>
                <div className="mr-10">
                  <p className="text-gray-500">Position</p>
                  <p className="font-bold">{user?.role_tag}</p>
                </div>
                <div className="mr-10">
                  <p className="text-gray-500">Email</p>
                  <p className="font-bold">{user?.email}</p>
                </div>
              </div>
              <div className="row-3 flex items-center">
                <Button
                  value="Add client"
                  class="text-black-900 border-none bg-gray-300 hover:bg-purple-300 rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6"
                  onClick={toggleAddClientForm}
                />

                <Button
                  value="Add access"
                  class="text-black-900 border-none bg-gray-300 hover:bg-purple-300 rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6"
                  onClick={toggleAddAccessForm}
                />

                <Button
                  value="Password reset"
                  class="text-black-900 border-none bg-gray-300 hover:bg-purple-300 rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6"
                  onClick={toggleResetPasswordVisibility}
                />

                <Button
                  value="Log out"
                  class="text-white border-none bg-red-300 hover:bg-red-600 rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6"
                  onClick={handleLogout}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col max-h-[calc(100vh - 10px)] max-w-[calc(100vh - 10px)] space-y-4">
          <div className="bg-white justify-between rounded-lg shadow-md space-y-2 p-2">
            <div className="flex items-center justify-between">
              <p className="text-black font-bold">Organization Management</p>
              <SearchBar onSearch={handleSearch} />
            </div>

            <div className="max-h-[220px] overflow-y-auto">
              <Table
                headers={organizationHeaders}
                values={paginatedValues}
                handleDelete={handleDelete}
              />
            </div>

            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onPreviousPage={handlePreviousPage}
              onNextPage={handleNextPage}
            />
          </div>

          <div className="flex-grow overflow-auto">
            <PendingRequestsTable
              requests={requests}
              handleApprove={handleApprove}
              handleDecline={handleDecline}
            />
          </div>
        </div>

        {showAddClientForm && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg w-96">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-semibold">Add New Client</h2>
                <FontAwesomeIcon
                  icon={faTimes}
                  className="text-gray-500 cursor-pointer"
                  onClick={toggleAddClientForm}
                />
              </div>
              <form onSubmit={handleFormSubmit}>
                <div className="mb-4 flex items-center">
                  <label
                    className="block text-sm text-gray-600 w-24 mr-4"
                    htmlFor="organization"
                  >
                    Organization
                  </label>
                  <input
                    type="text"
                    id="organization"
                    name="organization"
                    className="border border-gray-300 rounded-lg py-2 px-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4 flex items-center">
                  <label
                    className="block text-sm text-gray-600 w-24 mr-4"
                    htmlFor="email"
                  >
                    Admin Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="border border-gray-300 rounded-lg py-2 px-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4 flex items-center">
                  <label
                    className="block text-sm text-gray-600 w-24 mr-4"
                    htmlFor="type"
                  >
                    Type
                  </label>
                  <input
                    type="text"
                    id="type"
                    name="type"
                    className="border border-gray-300 rounded-lg py-2 px-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4 flex items-center">
                  <label
                    className="block text-sm text-gray-600 w-24 mr-4"
                    htmlFor="tagline"
                  >
                    Tagline
                  </label>
                  <input
                    type="text"
                    id="tagline"
                    name="tagline"
                    className="border border-gray-300 rounded-lg py-2 px-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4 flex items-center">
                  <label
                    className="block text-sm text-gray-600 w-24 mr-4"
                    htmlFor="firstName"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="border border-gray-300 rounded-lg py-2 px-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4 flex items-center">
                  <label
                    className="block text-sm text-gray-600 w-24 mr-4"
                    htmlFor="lastName"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="border border-gray-300 rounded-lg py-2 px-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <button
                  type="submit"
                  className={`${
                    isLoading
                      ? "text-black bg-gray-400"
                      : "bg-purple-900 text-white hover:bg-blue-600"
                  } rounded-lg py-2 px-4 float-right focus:outline-none focus:bg-blue-600`}
                  disabled={isLoading}
                >
                  Send invite
                </button>
              </form>
            </div>
          </div>
        )}
        {showAddAccessForm && (
          <AddAccessForm
            isAddingAdmin={isAddingAdmin}
            toggleForm={toggleAddAccessForm}
            orgId={selectedOrgId}
            name={selectedOrgName}
          />
        )}

        {showSuccessToast && (
          <Alert
            message={successMessage}
            color="green"
            onClose={() => setShowSuccessToast(false)}
            timeout={3000}
          />
        )}
        {error && <Alert message={error} color="red" />}
      </div>
    </>
  );
}

export default SettingsPage;
