import React, { useState, useContext, useEffect, useRef } from "react";
import Table from "./../../common/table";
import Modal from "./../../common/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faL } from "@fortawesome/free-solid-svg-icons";
import {
  faTimes,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { getData, updateData } from "./../../common/utils";
import SpinnerInfinity from "./../../common/spinner";
import DropDown from "../../common/dropDown";
import { addData } from "../../common/utils";
import t from "./../../assets/noData/type.png";
import trainer_icon from "./../../assets/Button UI files/trainers_icon_selected.png";

import { AppContext } from "./../../App";
// to be removed - after implementation of video tracking
const getRandomDate = () => {
  const today = new Date();
  const randomHour = Math.floor(Math.random() * 24);
  const randomMinute = Math.floor(Math.random() * 60);
  const formattedDate = today.toLocaleDateString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
  return `${formattedDate}, ${randomHour}:${
    randomMinute < 10 ? "0" : ""
  }${randomMinute} ${randomHour < 12 ? "AM" : "PM"}`;
};
// to be removed - after implementation of video tracking
const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
const fetchUsers = async (org_id, gym_id, token) => {
  try {
    const users = await getData(`users?orgId=${org_id}&gymId=${gym_id}`, token);
    return users;
  } catch (error) {
    console.error("Error fetching stats:", error);
  }
};
function UserTable() {
  const ulRef = useRef();
  const inputRef = useRef();
  const { appInfo, setAppInfo } = useContext(AppContext);
  const [trainers, setTrainers] = useState([]);
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchMemberQuery, setMemberSearchQuery] = useState("");
  const [gyms, setGyms] = useState(null);
  const [gym, setGym] = useState(null);
  const [error, setError] = useState(null);

  const fetchGyms = async () => {
    try {
      const org_id = appInfo.org.org_id;
      const gymsData = await getData(`gyms?org_id=${org_id}`, appInfo.token);
      setGyms(gymsData);
      setGym(gymsData[0]);
    } catch (error) {
      console.error("Error fetching gyms:", error);
      setError("Error fetching gyms. Please try again later.");
    }
  };
  const updateUserRole = async (userId, roleId) => {
    try {
      const body = {
        gymId: gym.gym_id,
        roleId: roleId,
      };
      const request = JSON.stringify(body);
      const response = await updateData(
        `gymUsers/userRole/${userId}`,
        request,
        appInfo.token
      );
      if (!response.ok) {
        throw new Error(response.message || "Failed to update user role");
      }

      console.log("updated successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error updating admin details:", error);
      setError("Error updating role. Please try again later.");
      return false;
    }
  };
  const addUser = async (gymId, name, email) => {
    try {
      const body = {
        name: name,
        email: email,
        gymIds: [gymId],
        roleId: 4,
        position: "Trainer",
      };
      const request = JSON.stringify(body);
      const response = await addData(`gymUsers`, request, appInfo.token);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to add user");
      }

      console.log("User access added successfully");
    } catch (error) {
      console.error("Error adding user:", error);
      setError("Error adding user. Please try again later.");
      return false;
    }
  };

  useEffect(() => {
    if (gym) {
      setCurrentPage(1);
      fetchUsers(appInfo.org.org_id, gym.gym_id, appInfo.token).then(
        (result) => {
          let t = result.filter((u) => u.role_id == 4);
          setTrainers(t);
          const tP = Math.ceil(t.length / itemsPerPage);
          const startIndex = 0;
          const endIndex = startIndex + itemsPerPage;
          const paginatedUsers = t.slice(startIndex, endIndex);

          setTableValues(
            paginatedUsers.map((user) => [
              user.name,
              user.lastVisited ?? getRandomDate(),
              user.uploadedVideos ?? getRandomNumber(0, 4),
              user.programDownloads ?? getRandomNumber(0, 50),
              <button onClick={() => handleDeleteUser(user.u_id)}>
                {<FontAwesomeIcon icon={faTrash} />}
              </button>,
            ])
          );
          setPaginationNumbers(
            Array.from({ length: tP }, (_, index) => index + 1)
          );
          setTotalPages(tP);
          let m = result.filter((u) => u.role_id == 5);
          setMembers(m);
          setIsLoading(false);
        }
      );
    }
  }, [gym]);

  useEffect(() => {
    fetchGyms();
  }, [appInfo]);

  const [searchTrainerQuery, setTrainerSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [newTrainer, setNewTrainer] = useState({
    name: "",
    email: "",
    selectedUserId: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tableValues, setTableValues] = useState(null);
  const [paginationNumbers, setPaginationNumbers] = useState(null);
  const itemsPerPage = 9;
  // const [selectedUser, setSelectedUser] = useState(null);

  const handleDeleteUser = (userId) => {
    updateUserRole(userId, 5).then(() => {
      setTrainers(trainers.filter((user) => user.id !== userId));
    });
  };

  const handleViewDetails = () => {
    setOpenModal(true);
  };

  const handleTrainerSearchChange = (event) => {
    setTrainerSearchQuery(event.target.value);
  };
  const handleMemberSearchChange = (event) => {
    setMemberSearchQuery(event.target.value);
  };
  const handleAddTrainer = (user) => {
    if (user.u_id) {
      updateUserRole(user.u_id, 4);
    }
    if (newTrainer.name.trim() !== "" && newTrainer.email.trim() !== "") {
      const newUser = {
        id: trainers.length + 1,
        name: newTrainer.name.trim(),
        email: newTrainer.email.trim(),
        lastVisited: "Date/time",
        uploadedVideos: 0,
        programDownloads: 0,
      };
      addUser(gym.gym_id, newUser.name, newUser.email).then(() => {
        setTrainers([...trainers, newUser]);
        setNewTrainer({ name: "", email: "", selectedUserId: "" });
        setOpenModal(false);
      });
    }
  };
  const handleCancelAddTrainer = () => {
    setNewTrainer({ name: "", email: "", selectedUserId: "" });
    setOpenModal(false);
    setIsNewUser(false);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleNewTrainerInvite = (page) => {
    setIsNewUser(true);
  };

  const handleGymSelection = (gymId) => {
    setGym(gyms[gymId]);

    console.log("Selected gym:", gyms[gymId]);
  };
  const tableHeaders = [
    "Name",
    "Last Visited",
    "Uploaded Videos",
    "Program Downloads",
    "Actions",
  ];

  useEffect(() => {
    let filteredTrainers = trainers.filter((user) =>
      user.name.toLowerCase().includes(searchTrainerQuery.toLowerCase())
    );
    const tP = Math.ceil(filteredTrainers.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedUsers = filteredTrainers.slice(startIndex, endIndex);

    const remainingItems = paginatedUsers.length % itemsPerPage;
    const buffersNeeded =
      remainingItems === 0 ? 0 : itemsPerPage - remainingItems;

    const tableValues = paginatedUsers.map((user) => [
      user.name,
      user.lastVisited,
      user.uploadedVideos,
      user.programDownloads,
      <button onClick={() => handleDeleteUser(user.u_id)}>
        <FontAwesomeIcon icon={faTrash} />
      </button>,
    ]);

    for (let i = 0; i < buffersNeeded; i++) {
      tableValues.push(["", "", "", "", ""]);
    }

    setTableValues(tableValues);
    setPaginationNumbers(Array.from({ length: tP }, (_, index) => index + 1));
    setTotalPages(tP);
  }, [searchTrainerQuery, currentPage]);

  return (
    <div className="p-6 h-screen">
      <div className="flex flex-row align-middle py-4 px-2">
        <img src={trainer_icon} alt="trainers" className="w-8 h-8 mt-4" />
        <h1 className="font-extrabold text-3xl pl-6 pb-2 mt-2 text-[#64006c]">
          Trainer Management
        </h1>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="text"
          placeholder="Search by Name"
          value={searchTrainerQuery}
          onChange={handleTrainerSearchChange}
          className="p-2 border rounded mr-2 flex-grow"
        />

        {gyms != null && gyms.length > 0 && (
          <div className="pr-2">
            <DropDown
              options={gyms.map((gym) => gym.name)}
              onSelect={handleGymSelection}
            />
          </div>
        )}
        <button
          type="button"
          className="inline-flex justify-center w-32 rounded-md border font-bold  shadow-sm px-4 py-2 bg-green-500 text-sm hover:bg-green-700  text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
          onClick={() => handleViewDetails()}
        >
          Add Trainer
          <div className="ml-1">
            <FontAwesomeIcon icon={faPlus} />
          </div>
        </button>
      </div>
      {isLoading ? (
        <SpinnerInfinity
          stroke={appInfo.org.color1}
          size={18}
          color={appInfo.org.color2}
          pTop={10}
          pLeft={0}
          className=" col-start-2 mt-[10rem] ml-[33rem]"
        />
      ) : tableValues.length > 0 ? (
        <Table headers={tableHeaders} values={tableValues} headerSize="4/5" />
      ) : (
        <img src={t} alt="logo" className="h-80 ml-[30rem]" />
      )}
      {!isLoading && tableValues.length > 0 && (
        <div className="mt-4 flex justify-center items-center">
          <button
            disabled={currentPage === 1}
            className="px-3 py-[7px] bg-gray-500 text-gray-100 rounded-md mr-2"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          {paginationNumbers?.map((number) => (
            <button
              key={number}
              className={`px-3 py-[7px] rounded-md mr-2 ${
                number === currentPage
                  ? "bg-purple-500 text-white"
                  : "bg-gray-500 text-gray-100"
              }`}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </button>
          ))}
          <button
            disabled={currentPage === totalPages}
            className="px-3 py-[7px] bg-gray-500 text-gray-100 rounded-md ml-2"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      )}
      {/* Modal */}
      {openModal && (
        <Modal modalHeader="Add Trainer" toggleModal={handleCancelAddTrainer}>
          <form onSubmit={handleAddTrainer} className="flex flex-col h-96">
            {/* Search input */}
            {!isNewUser && (
              <>
                {" "}
                <label className="flex flex-row gap-8">
                  <input
                    type="text"
                    value={searchMemberQuery}
                    onChange={handleMemberSearchChange}
                    ref={inputRef}
                    placeholder="Search users..."
                    className="p-2 border rounded w-96"
                  />
                </label>
                {/* Display filtered options */}
                <div
                  ref={ulRef}
                  className=" border-t-2 border-l-2 border-r-2 border-b-2 p-2 mt-2 rounded h-72 max-h-72 overflow-y-auto"
                >
                  {members
                    .filter((user) =>
                      user.name
                        .toLowerCase()
                        .includes(searchMemberQuery.toLowerCase())
                    )
                    .map((user, index) => (
                      <div
                        key={user.u_id || index}
                        className="submit cursor-pointer hover:bg-gray-200 p-2 flex justify-between"
                        onClick={() => handleAddTrainer(user)}
                        // onClick={() => console.log(user)}
                      >
                        {user.name} {<FontAwesomeIcon icon={faPlus} />}
                      </div>
                    ))}
                </div>
                <div className="flex justify-end mt-12">
                  <button
                    type="button"
                    className="p-2 border rounded"
                    onClick={handleNewTrainerInvite}
                  >
                    Invite New Trainer
                  </button>
                </div>
              </>
            )}
            {/* If user chooses to add a new user, show input fields */}
            {isNewUser && (
              <>
                <label className="flex flex-row gap-8 max-w-96 mt-12 mb-8">
                  Name
                  <input
                    type="text"
                    value={newTrainer.name}
                    onChange={(e) =>
                      setNewTrainer({ ...newTrainer, name: e.target.value })
                    }
                    className="p-2 border rounded w-96"
                  />
                </label>
                <label className="flex flex-row gap-8 max-w-96 mb-8">
                  Email
                  <input
                    type="email"
                    value={newTrainer.email}
                    onChange={(e) =>
                      setNewTrainer({ ...newTrainer, email: e.target.value })
                    }
                    className="p-2 border rounded w-96"
                  />
                </label>

                {/* Buttons */}
                <div className="flex justify-end mt-12">
                  <button
                    type="submit"
                    className="p-2 border rounded"
                    onClick={() => console.log(newTrainer)}
                  >
                    Add Trainer
                  </button>
                  <button
                    type="button"
                    className="p-2 border rounded ml-2"
                    onClick={handleCancelAddTrainer}
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
          </form>
        </Modal>
      )}
    </div>
  );
}

export default UserTable;
