import { Route, Routes } from "react-router-dom";
import SignIn from "./authentication/signIn";
import ProtectedRoutes from "./ProtectedRoutes";
import expModules from "./modules";
import { useContext, useEffect } from "react";
import { AppContext } from "./App";
import { isExpired } from "react-jwt";
import { useNavigate } from "react-router-dom";

function Views() {
  const { appInfo } = useContext(AppContext);
  let userRole = appInfo?.userInfo?.role_id ?? 0;
  let access = expModules[userRole];

  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/resetPassword" element={<SignIn />} />
      <Route path="/verifyEmail" element={<SignIn />} />
      <Route element={<ProtectedRoutes />}>
        {access.map((module) => (
          <Route
            key={module.name}
            path={module.routeProps.path}
            element={module.routeProps.element}
          />
        ))}
      </Route>
    </Routes>
  );
}

export default Views;
