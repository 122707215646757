import React, { useState, useContext } from "react";
import { AppContext } from "../../App";
import { addData } from "../../common/utils";
import Alert from "./Alert";

function CustomizeBrand({ closeModal }) {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [logo, setLogo] = useState(null);
  const [logoPreviewUrl, setLogoPreviewUrl] = useState("");
  const [drag, setDrag] = useState(false);
  const { appInfo, setAppInfo } = useContext(AppContext);
  const [color1, setColor1] = useState(appInfo.org.color1 || "#aabbcc");
  const [color2, setColor2] = useState(appInfo.org.color2 || "#aabbcc");
  const [buttonText, setButtonText] = useState("Save Changes");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("green");
  const [isLoading, setIsLoading] = useState(false);
  const [tagline, setTagline] = useState(
    appInfo?.org?.tagline ?? "Taking experience to user"
  );

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogo(file);
      setLogoPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleColor1Change = (event) => {
    setColor1(event.target.value);
  };

  const handleColor2Change = (event) => {
    setColor2(event.target.value);
  };

  const handleTaglineChange = (event) => {
    setTagline(event.target.value);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDrag(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDrag(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDrag(false);
  };

  const handleDop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDrag(false);
    const files = event.dataTransfer.files;

    if (files && files.length > 1) {
      setAlertMessage("You can upload only 1 file at a time");
      setAlertColor("red");
      setShowSuccessAlert(true);
    }

    if (files && files.length === 1) {
      const file = files[0];
      setLogo(file);
      setLogoPreviewUrl(URL.createObjectURL(file));
      event.dataTransfer.clearData();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    await customizeBrand();
    setButtonText("OK");
    setAlertMessage("Customization Updated Successfully!");
    setAlertColor("green");
    setShowSuccessAlert(true);
    setIsLoading(false);
  };

  const customizeBrand = async () => {
    try {
      const formData = new FormData();
      if (logo) {
        formData.append("file", logo);
        formData.append("fileName", logo.name);
      }

      if (tagline) {
        formData.append("tagline", tagline);
      }

      formData.append("color1", color1);
      formData.append("color2", color2);
      const result = await addData(
        `customizeBrand?orgId=${appInfo.org.org_id}`,
        formData,
        appInfo.token,
        true
      );
      if (!result) {
        throw new Error(result.message || "Failed to update logo");
      }

      console.log("Customization successful!");
      const updatedAppInfo = { ...appInfo };

      if (result.logo) {
        updatedAppInfo.org.logo = result.logo;
      }

      if (color1) {
        updatedAppInfo.org.color1 = color1;
      }

      if (color2) {
        updatedAppInfo.org.color2 = color2;
      }

      if (tagline) {
        updatedAppInfo.org.tagline = tagline;
      }

      setAppInfo(updatedAppInfo);
    } catch (error) {
      console.error("Error updating customization:", error);
      setAlertMessage("Error updating customization. Please try again later.");
      setAlertColor("red");
      setShowSuccessAlert(true);
      return false;
    }
  };

  const handleModalClose = () => {
    if (buttonText === "OK") {
      closeModal();
    }
  };

  return (
    <>
      {showSuccessAlert && (
        <Alert
          message={alertMessage}
          timeout={3000}
          color={alertColor}
          onClose={() => setShowSuccessAlert(false)}
        />
      )}
      <form onSubmit={handleSubmit}>
        <div>
          <div
            className="flex items-center gap-4 w-full"
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDop}
          >
            <label
              htmlFor="logo"
              className="flex-1 flex flex-col items-center justify-center h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
              style={{
                backgroundImage: `url(${logoPreviewUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                {logoPreviewUrl ? (
                  <img
                    src={logoPreviewUrl}
                    alt="Uploaded logo"
                    className="w-full h-full object-contain max-h-0"
                  />
                ) : (
                  <>
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500">
                      SVG, PNG, JPG or GIF (MAX. 800x400px)
                    </p>
                  </>
                )}
              </div>
              <input
                id="logo"
                type="file"
                onChange={handleLogoChange}
                className="hidden"
              />
            </label>
          </div>

          <div className="flex items-center mt-4 gap-4">
            <label
              htmlFor="position"
              className="col-span-1 block text-left text-sm font-medium text-gray-900 justify-start"
            >
              Tagline
            </label>
            <input
              type="text"
              id="position"
              value={tagline}
              onChange={handleTaglineChange}
              className="col-span-2 text-sm font-medium bg-gray-300 block"
              required
            />
          </div>

          <div className="flex items-center mt-4 gap-4">
            <label htmlFor="colors" className="text-sm font-medium">
              Colors
            </label>
            <input
              type="color"
              id="color1"
              value={color1}
              onChange={handleColor1Change}
              className="w-16 h-10 border-2 border-gray-300 rounded-lg"
            />
            <input
              type="color"
              id="color2"
              value={color2}
              onChange={handleColor2Change}
              className="w-16 h-10 border-2 border-gray-300 rounded-lg"
            />
          </div>
          <div className="flex mt-4 items-center justify-center gap-8">
            <button
              onClick={handleModalClose}
              type="submit"
              className={`border-none ${
                isLoading
                  ? "text-black bg-gray-400"
                  : "text-white bg-purple-900 hover:bg-red-500"
              } rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6`}
              disabled={isLoading}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default CustomizeBrand;
