import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../App";
import { updateData, addData, getData } from "../../common/utils";
import Alert from "./Alert";

function EditProfile({ data = null, closeModal }) {
  const [fullName, setFullName] = useState("");
  const [oldEmail, setOldEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [confirmNewEmail, setConfirmNewEmail] = useState("");
  const [buttonText, setButtonText] = useState("Save Changes");
  const [alertColor, setAlertColor] = useState("green");
  const [alertMessage, setAlertMessage] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { appInfo } = useContext(AppContext);

  const handleFullNameChange = (event) => setFullName(event.target.value);
  const handleOldEmailChange = (event) => setOldEmail(event.target.value);
  const handleNewEmailChange = (event) => setNewEmail(event.target.value);
  const handleConfirmNewEmailChange = (event) =>
    setConfirmNewEmail(event.target.value);

  const handleModalClose = () => {
    if (buttonText === "OK") {
      closeModal();
      window.location.reload();
    }
  };

  const triggerEmail = async (updatedUserInfo) => {
    try {
      const webHost = process.env.REACT_APP_WEB_URL;
      let body = {
        templateName: "update_email.hbs",
        data: {
          user: appInfo.userInfo.name,
          redirectionUrl: `${webHost}verifyEmail?verifyAndUpdateEmail=${updatedUserInfo.email_token}`,
        },
        subject: "Update your email",
        toEmail: newEmail,
      };
      body = JSON.stringify(body);
      const response = await addData(
        `/emails/triggerEmail`,
        body,
        appInfo.token
      );

      if (!response.message) {
        const errorData = await response.JSON();
        throw new Error(errorData.message || "Failed to send email");
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    if (data) {
      setFullName(data.name || "");
      setOldEmail(data.email || "");
    }
  }, [data]);

  const updateUserDetails = async (uid, token) => {
    try {
      const body = JSON.stringify({
        name: fullName,
        oldEmail: oldEmail,
        newEmail: newEmail,
        confirmNewEmail: confirmNewEmail,
      });
      if (newEmail) {
        if (newEmail !== confirmNewEmail) {
          throw new Error(
            "New email and confirm new email fields do not match"
          );
        }
      }
      await updateData(`users/${uid}`, body, token);
      const userInfo = await getData(`users/byEmail?email=${oldEmail}`);
      return userInfo;
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const updatedUserInfo = await updateUserDetails(
        appInfo.userInfo.u_id,
        appInfo.token
      );
      if (newEmail && confirmNewEmail && fullName) {
        await triggerEmail(updatedUserInfo);
        setAlertMessage(
          "Your email will be updated once it's verified. Please keep an eye on your inbox for confirmation."
        );
      } else {
        setAlertMessage("Profile updated successfully!");
      }
      setAlertColor("green");
      setShowSuccessAlert(true);
      setButtonText("OK");
      setIsLoading(false);
    } catch (error) {
      console.log("error:", error.code, error.message);
      setAlertColor("red");
      setAlertMessage(error.message);
      setShowSuccessAlert(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      {showSuccessAlert && (
        <Alert
          message={alertMessage}
          timeout={3000}
          color={alertColor}
          onClose={() => setShowSuccessAlert(false)}
        />
      )}

      <form onSubmit={handleSubmit}>
        <div className="grid gap-6">
          <div className="grid grid-cols-3 items-center gap-8">
            <label
              htmlFor="fullName"
              className="col-span-1 text-left block text-sm font-medium text-gray-900"
            >
              Full name
            </label>
            <input
              type="text"
              id="fullName"
              onChange={handleFullNameChange}
              value={fullName}
              className="col-span-2 text-sm font-medium bg-gray-300 w-full"
              required
            />
          </div>
          <div className="grid grid-cols-3 items-center gap-8">
            <label
              htmlFor="oldEmail"
              className="col-span-1 text-left block text-sm font-medium text-gray-900"
            >
              Old Email
            </label>
            <input
              type="email"
              id="oldEmail"
              onChange={handleOldEmailChange}
              value={oldEmail}
              className="col-span-2 text-sm font-medium bg-gray-300 w-full"
              required
              disabled={true}
            />
          </div>
          <div className="grid grid-cols-3 items-center gap-8">
            <label
              htmlFor="newEmail"
              className="col-span-1 text-left block text-sm font-medium text-gray-900"
            >
              New Email
            </label>
            <input
              type="email"
              id="newEmail"
              onChange={handleNewEmailChange}
              value={newEmail}
              className="col-span-2 text-sm font-medium bg-gray-300 w-full"
            />
          </div>
          <div className="grid grid-cols-3 items-center gap-8">
            <label
              htmlFor="confirmNewEmail"
              className="col-span-1 text-left block text-sm font-medium text-gray-900"
            >
              Confirm New Email
            </label>
            <input
              type="email"
              id="confirmNewEmail"
              onChange={handleConfirmNewEmailChange}
              value={confirmNewEmail}
              className="col-span-2 text-sm font-medium bg-gray-300 w-full"
            />
          </div>
          <div className="flex items-center justify-center gap-8">
            <button
              type="submit"
              onClick={handleModalClose}
              className={`border-none ${
                isLoading
                  ? "text-black bg-gray-400"
                  : "text-white bg-purple-900 hover:bg-red-500"
              } rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6`}
              disabled={isLoading}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditProfile;
