import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

import Table from "../../common/table";
import { AppContext } from "../../App";
import { getData, updateData } from "../../common/utils";
import Modal from "../../common/modal";
import AddAccess from "./addAccess";

function PeerAccessInfo({ gyms, org }) {
  const { appInfo } = useContext(AppContext);
  const [gymAdmins, setGymAdmins] = useState(null);
  const [isEditAccessVisible, setIsEditAccessVisible] = useState(false);
  const [currentAdmin, setCurrentAdmin] = useState(null);

  const toggleEditAccessVisibility = (admin = null) => {
    setIsEditAccessVisible(!isEditAccessVisible);
    //console.log("Current Admin", admin);
    setCurrentAdmin(admin);
  };

  useEffect(() => {
    const fetchAdmins = async (orgId) => {
      try {
        const gymAdminsData = await getData(
          `gymUsers?org_id=${orgId}`,
          appInfo.token
        );
        //console.log("Gym Admins:", gymAdminsData);
        setGymAdmins(gymAdminsData);
      } catch (error) {
        console.error("Error fetching gyms:", error);
      }
    };

    fetchAdmins(org.org_id);
  }, []);

  const otherAdmins = [];

  if (gymAdmins) {
    gymAdmins.forEach((item) => {
      const existingUser = otherAdmins.find((user) => user.u_id === item.u_id);
      if (existingUser) {
        if (!existingUser.gyms_with_access.includes(item.gym_name)) {
          existingUser.gyms_with_access += `, ${item.gym_name}`;
        }
      } else {
        otherAdmins.push({
          u_id: item.u_id,
          gym_id: item.gym_id,
          user_name: item.name,
          gyms_with_access: item.gym_name,
          email: item.email,
          position: item.position,
          role_id: item.role_id,
        });
      }
    });

    //console.log("Admins table data", otherAdmins);
  }

  const headers = [
    "Full Name",
    "Email",
    "Position",
    "Gyms with access",
    "Action",
  ];

  const values = otherAdmins.map((admin) => {
    return [
      admin?.user_name,
      admin?.email,
      admin?.position,
      admin?.gyms_with_access,
      <>
        <button
          style={{ backgroundColor: "#f6f5f7" }}
          onClick={() => toggleEditAccessVisibility(admin)}
          className="text-black-900 border-none bg-gray-300 hover:bg-purple-300 rounded-full font-medium text-sm px-1 py-0.5 me-2 mb-2"
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </button>{" "}
        | &nbsp; &nbsp;
        <button
          style={{ backgroundColor: "#f6f5f7" }}
          className="text-black-900 border-none bg-gray-300 hover:bg-red-300 rounded-full font-medium text-sm px-1 py-0.5 me-2 mb-2"
          onClick={() => deleteUser(admin?.u_id, appInfo.token)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </>,
    ];
  });

  const deleteUser = async (uid, token) => {
    try {
      const body = JSON.stringify({ status: 3 });
      await updateData(`users/${uid}`, body, token);
      if (uid === appInfo.userInfo.u_id) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        window.location.reload();
      }
      console.log("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user: ", error);
    }
  };

  return (
    <>
      {isEditAccessVisible && (
        <Modal
          modalHeader={"Edit Access"}
          toggleModal={() => toggleEditAccessVisibility()}
        >
          <AddAccess
            action={"Edit"}
            gyms={gyms}
            data={currentAdmin}
            closeModal={() => toggleEditAccessVisibility()}
          />
        </Modal>
      )}
      <div className="grid gap-4 h-full">
        <div className="accessCard bg-white justify-between rounded-lg shadow-md p-4">
          <div className="others-access mr-10 mb-4">
            <p className="text-gray-500 font-bold">Others with access</p>
          </div>
          <div style={{ width: "50rem", height: "calc(100% - 10px)" }}>
            <Table headers={headers} values={values} />
          </div>
        </div>
      </div>
    </>
  );
}

export default PeerAccessInfo;
