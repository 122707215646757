import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faCheckCircle,
  faBan,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import VideoPlayer from "./videoPlayer";
import { getData, deleteData, updateData } from "../../common/utils";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import no_video from "../../assets/noData/videos.png";

export default function VideosComponent({
  roleId,
  gymId,
  token,
  videoService,
  showPopup,
  userId,
  gyms,
}) {
  const params = useParams();
  const exerciseId = parseInt(params.exerciseId);
  const selectedStatus = params.status;

  const exerciseName = videoService.getExerciseName(exerciseId);

  const [videos, setVideos] = useState([]);
  const [showGymList, setShowGymList] = useState([]);

  useEffect(() => {
    const uuid = videoService.registerVideosListener(setVideos);
    videoService
      .GetVideosForExercise(exerciseId, gymId, selectedStatus)
      .then((response) => {
        setVideos(response);
      });
    return () => {
      videoService.unRegisterVideosListener(uuid);
    };
  }, [exerciseId, gymId, showPopup, selectedStatus]);

  console.log(videos);
  console.log("userGyms", gyms);

  const addAccess = (video_id, gym_id) => {
    try {
      getData(`exercise/videos/addAccess/${video_id}/${gym_id}`, token).then(
        () => {
          alert("Access added successfully");
          videoService.GetVideosForExercise(exerciseId, gymId, selectedStatus);
          videoService.getExerciseList(gymId, "Add Access");
        }
      );
    } catch (error) {
      console.log("Error adding access:", error);
    }
  };

  const removeAccess = (video_id, gym_id) => {
    try {
      getData(`exercise/videos/removeAccess/${video_id}/${gym_id}`, token).then(
        () => {
          alert("Access removed successfully");
          videoService.getExerciseList(gymId, "Remove Access");
          videoService.GetVideosForExercise(exerciseId, gymId, selectedStatus);
        }
      );
    } catch (error) {
      console.log("Error removing access:", error);
    }
  };

  const handleDelete = (video_id, UserId, approval_status) => {
    if (window.confirm("Are you sure you want to delete this video?") === false)
      return;
    else {
      // eslint-disable-next-line no-lone-blocks
      {
        deleteData(`exercise/videos/delete/${video_id}/${UserId}`, token)
          .then(() => {
            //toast("Video deleted successfully");
            if (roleId === 4) {
              if (approval_status === "pending") {
                alert("Video deleted successfully");
              } else {
                alert("Video deletion request sent successfully");
              }
            } else {
              alert("Video deleted successfully");
            }
            videoService.GetVideosForExercise(
              exerciseId,
              gymId,
              selectedStatus
            );
            videoService.getExerciseList(gymId, "Delete");
            // console.log("")
          })
          .catch((error) => {
            console.log("Error deleting video:", error);
          });
      }
    }
  };

  const handleApprove = (video_id) => {
    getData(`exercise/videos/approve/${video_id}`, token).then(() => {
      alert("Video approved successfully");
      videoService.GetVideosForExercise(exerciseId, gymId, selectedStatus);
      videoService.getExerciseList(gymId, "Approve");
      //getInitialData();
    });
  };

  const handleReject = (video_id) => {
    getData(`exercise/videos/reject/${video_id}`, token).then(() => {
      alert("Video deletion approval rejected successfully");
      videoService.GetVideosForExercise(exerciseId, gymId, selectedStatus);
      videoService.getExerciseList(gymId, "Reject");
      //getInitialData();
    });
  };

  return (
    <div className="bg-white w-[calc(100%-150px)]  overflow-y-auto p-2 h-[calc(100%-90px)] border-2 rounded-md">
      <h1 className="text-2xl font-extrabold mb-4 ">{exerciseName}</h1>
      <div className="p-4">
        {videos?.length > 0 ? (
          <ul className="flex flex-wrap gap-4">
            {videos.map((video) => (
              <VideoCard key={video.video_id} video={video} />
            ))}
          </ul>
        ) : (
          <div className="flex flex-col" style={{ alignItems: "center" }}>
            <img src={no_video} alt="logo" className="h-64" />
            <div className="text-gray-300">No video is available</div>
          </div>
        )}
      </div>
    </div>
  );

  function VideoCard({ video }) {
    const [showGymList, setShowGymList] = useState(false);
    return (
      <li
        key={video.video_id}
        className="flex flex-col border border-gray-300 rounded-md"
      >
        <div>
          <VideoPlayer
            videoKey={video.video_key}
            thumbnailKey={video.thumbnail_image}
          />
        </div>
        <div className="flex justify-between items-center h-8 p-2">
          <h2 className="text-md">{video.trainer_name}</h2>
          <div className="space-x-4">
            {(roleId !== 4 ||
              (video.uploaded_User_id === userId &&
                video.approval_status !== "pending_delete")) && (
              <>
                <button
                  onClick={() =>
                    handleDelete(video.video_id, userId, video.approval_status)
                  }
                  title="Delete"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </>
            )}
            {roleId !== 4 && video.approval_status === "pending" && (
              <>
                <button
                  onClick={() => handleApprove(video.video_id)}
                  title="approve"
                >
                  <FontAwesomeIcon icon={faCheckCircle} />
                </button>{" "}
              </>
            )}
            {roleId !== 4 && video.approval_status === "pending_delete" && (
              <>
                <button
                  onClick={() => handleReject(video.video_id)}
                  title="Reject deletion"
                >
                  <FontAwesomeIcon icon={faBan} />
                </button>{" "}
              </>
            )}
          </div>
          {roleId !== 4 && (
            <div className="relative">
              <button
                className="flex items-center justify-center w-8 h-8 rounded-full"
                title="Manage Access"
                // style={{
                //   zIndex: "-2",
                //   position: "absolute",
                //   right: "10px",
                //   top: "-15px",
                // }}
                onClick={() => {
                  setShowGymList(!showGymList);
                }}
              >
                <FontAwesomeIcon icon={faCog} />
              </button>
              {showGymList && (
                <div className="absolute  top-5 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg">
                  <ul className="py-2">
                    {gyms?.map((gym) => (
                      <li
                        key={gym.gym_id}
                        className="flex items-center justify-between px-4 py-2 hover:bg-gray-100"
                      >
                        <span>{gym.name}</span>
                        {video.gym_ids.includes(gym.gym_id) ? (
                          <button
                            className="text-red-500 rounded"
                            onClick={() =>
                              removeAccess(video.video_id, gym.gym_id)
                            }
                          >
                            Remove Access
                          </button>
                        ) : (
                          <button
                            className="text-green-500 rounded"
                            onClick={() =>
                              addAccess(video.video_id, gym.gym_id)
                            }
                          >
                            Add Access
                          </button>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </li>
    );
  }
}
