import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import Header from "../../common/header";
import { AppContext } from "../../App";
import SignOut from "../../authentication/signOut";
import ResetPassword from "./resetPassword";
import CustomizeBrand from "./customizeBrand";
import GymMaintainence from "./gymMaintainenece";
import Modal from "../../common/modal";
import AddAccess from "./addAccess";
import { getData } from "../../common/utils";
import PeerAccessInfo from "./peerAccessInfo";
import SettingsPage from "./redprintAdminView";
import logo from "../../assets/beta_logo.png";
import EditProfile from "./editProfile";
import SpinnerInfinity from "./../../common/spinner";
import { hexToRgbString } from "../../common/utils";
import settings_icon from "../../assets/Button UI files/settings_icon_selected.png";

function Settings() {
  const [error, setError] = useState(null);
  const [gyms, setGyms] = useState([]);
  const [gymUsers, setGymUsers] = useState(null);
  const [user, setUser] = useState(null);
  const { appInfo, setAppInfo } = useContext(AppContext);
  const [isVisitor, setIsVisitor] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  function isLightColor(rgb) {
    const luminance = calculateLuminance(rgb);
    const threshold = 0.5;
    return luminance > threshold;
  }
  function calculateLuminance(rgb) {
    const r = rgb[0] / 255;
    const g = rgb[1] / 255;
    const b = rgb[2] / 255;

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }
  const fetchOrg = async (orgId, token) => {
    try {
      const org = await getData(`orgs/${orgId}`, token);
      if (org.color1 == undefined || org.color2 == undefined) {
        if (org.color1 == undefined) {
          org.color1 = "#fe5a50";
        }
        if (org.color2 == undefined) {
          org.color2 = "#5b0069";
        }
      }

      var rgb1 = hexToRgbString(org.color1);
      var rgb2 = hexToRgbString(org.color2);
      var colors1 = rgb1.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      var colors2 = rgb2.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      var brightnessAdjustment = 0.5;
      const isLightColor1 = isLightColor([colors1[1], colors1[2], colors1[3]]);
      const isLightColor2 = isLightColor([colors2[1], colors2[2], colors2[3]]);
      if (isLightColor1) {
        org.color1 = `rgb(${Math.floor(
          colors1[1] * brightnessAdjustment
        )},${Math.floor(colors1[2] * brightnessAdjustment)},${Math.floor(
          colors1[3] * brightnessAdjustment
        )})`;
      }

      if (isLightColor2) {
        org.color2 = `rgb(${Math.floor(
          colors2[1] * brightnessAdjustment
        )},${Math.floor(colors2[2] * brightnessAdjustment)},${Math.floor(
          colors2[3] * brightnessAdjustment
        )})`;
      }

      org.tc1 = isLightColor1 ? "rgb(0, 0, 0, 1)" : "rgb(255, 255, 255, 1)";
      org.tc2 = isLightColor2 ? "rgb(0, 0, 0, 1)" : "rgb(255, 255, 255, 1)";

      setAppInfo((prevAppInfo) => ({
        ...prevAppInfo,
        org: org,
      }));
      return org;
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };
  const handleOrgClick = (orgId) => {
    setSelectedOrgId(orgId);
    setIsVisitor(true);
    // Do whatever you need to do with the orgId
  };
  const fetchUser = async (uid, token) => {
    try {
      const users = await getData(`users?userId=${uid}`, token);
      return users[0];
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };
  const updateAppInfo = (newUserId, newUserInfo) => {
    setAppInfo((prevInfo) => {
      const updatedInfo = {
        ...prevInfo,
        user: newUserId,
        userInfo: newUserInfo,
      };
  
      localStorage.setItem("appContext", JSON.stringify(updatedInfo));
      return updatedInfo;
    });
  };
  useEffect(() => {
    //debugger;
    if (isVisitor) {
      fetchOrg(selectedOrgId, appInfo.token).then((res1) => {
        console.log(selectedOrgId, res1);
        //debugger;
        getData(`gymUsers/${res1?.owner_id}`, appInfo.token).then((res3) => {
          console.log(res3);
          fetchUser(res3[0].u_id, appInfo?.token).then((res2) => {
            setAppInfo((prevAppInfo) => ({
              ...prevAppInfo,
              user: res3[0].u_id,
              userInfo: res2,
            }));
            fetchGyms(selectedOrgId);
            setGymUsers(res3[0]);
            //setRedprintInfo(res2.role_id === 1 ? res2 : null);
            updateAppInfo(res3[0].u_id, res2);
            navigate("/settings");
          });
        });
      });
    }
  }, [isVisitor]);

  console.log("app", appInfo);

  const fetchUsers = async () => {
    try {
      const gymUsersData = await getData(
        `gymUsers/${appInfo?.userInfo?.u_id}`,
        appInfo.token
      );
      setGymUsers(gymUsersData[0]);
      if (!isVisitor) {
        setUser(gymUsersData[0]);
      }
      return gymUsersData[0];
    } catch (error) {
      console.error("Error fetching gym users:", error);
      setError("Error fetching gym users. Please try again later.");
    }
  };

  const fetchGyms = async (orgId) => {
    try {
      const gymsData = await getData(`gyms?org_id=${orgId}`, appInfo.token);
      setGyms(gymsData);
    } catch (error) {
      console.error("Error fetching gyms:", error);
      setError("Error fetching gyms. Please try again later.");
    }
  };

  const fetchData = async () => {
    try {
      await fetchUsers().then(async (gymUsersData) => {
        if (gymUsersData) {
          const orgId = gymUsersData.org_id;
          await fetchGyms(orgId);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred. Please try again later.");
    }
  };

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, []);

  let role = appInfo?.userInfo?.role_id ?? 0;

  const navigate = useNavigate();

  const handleLogout = () => {
    SignOut(navigate, setAppInfo);
  };

  const [isAddAccessVisible, setIsAddAccessVisible] = useState(false);
  const [isResetPasswordVisible, setIsResetPasswordVisible] = useState(false);
  const [isCustomizeBrandVisible, setIsCustomizeBrandVisible] = useState(false);
  const [isEditProfileVisible, setIsEditProfileVisible] = useState(false);

  const toggleAddAccessVisibility = () => {
    setIsAddAccessVisible(!isAddAccessVisible);
  };

  const toggleResetPasswordVisibility = () => {
    setIsResetPasswordVisible(!isResetPasswordVisible);
  };

  const toggleCustomizeBrandVisibility = () => {
    setIsCustomizeBrandVisible(!isCustomizeBrandVisible);
  };

  const toggleEditProfileVisibility = () => {
    setIsEditProfileVisible(!isEditProfileVisible);
  };

  return (
    <div className="h-screen">
      <div className="flex flex-row align-middle py-4 px-2">
        <img src={settings_icon} alt="settings" className="w-8 h-8 mt-4" />
        <h1 className="font-extrabold text-3xl pl-6 pb-2 mt-2 text-[#64006c]">
          Settings
        </h1>
      </div>

      {isLoading && (
        <SpinnerInfinity
          stroke={appInfo?.org?.color1 ?? "#FC5A02"}
          size={18}
          color={appInfo?.org?.color2 ?? "#9726D1"}
          pTop={16}
          pLeft={10}
        />
      )}
      {!isLoading && isAddAccessVisible && (
        <Modal
          modalHeader={"Add Access"}
          toggleModal={toggleAddAccessVisibility}
        >
          <AddAccess
            action={"Add"}
            gyms={gyms}
            closeModal={toggleAddAccessVisibility}
          />
        </Modal>
      )}

      {!isLoading && isResetPasswordVisible && (
        <Modal toggleModal={toggleResetPasswordVisibility}>
          <ResetPassword closeModal={toggleResetPasswordVisibility} />
        </Modal>
      )}

      {!isLoading && isCustomizeBrandVisible && (
        <Modal toggleModal={toggleCustomizeBrandVisibility}>
          <CustomizeBrand closeModal={toggleCustomizeBrandVisibility} />
        </Modal>
      )}

      {!isLoading && isEditProfileVisible && (
        <Modal toggleModal={toggleEditProfileVisibility}>
          <EditProfile
            data={gymUsers}
            closeModal={toggleEditProfileVisibility}
          />
        </Modal>
      )}

      {!isLoading && (
        <div className="flex flex-col space-y-4">
          <div className="max-w-[864px]">
            {(role !== 1 ||
              (role === 1 && isVisitor && gymUsers?.role_id === 2)) && (
              <div className="flex justify-between gap-4">
                <div className="settingsCard bg-white justify-between rounded-lg shadow-md p-4">
                  <div className="row-1 flex items-center mb-4">
                    <div className="logoRow flex-shrink-0 mr-2">
                      <img
                        src={appInfo?.org?.logo ?? logo}
                        alt="logo"
                        className="client-logo w-16 h-auto"
                      />
                    </div>
                    <Header
                      title={appInfo?.org?.name ?? "Redprint"}
                      subtitle={
                        appInfo?.org?.tagline ?? "Taking experience to user"
                      }
                      titleClass="font-bold"
                      subTitleClass="text-gray-500"
                    />
                    {(role === 2 || (isVisitor && gymUsers?.role_id === 2)) && (
                      <div className="ml-4">
                        <button
                          style={{ backgroundColor: "#f6f5f7" }}
                          onClick={toggleCustomizeBrandVisibility}
                          className="text-black-900 border-none bg-gray-300 hover:bg-purple-300 rounded-full font-medium text-sm text-sm px-1 py-0.5 me-2 mb-2"
                        >
                          {<FontAwesomeIcon icon={faPenToSquare} />}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="row-2 flex items-center mb-4">
                    <div className="mr-10">
                      <p className="text-gray-500">Name</p>
                      <p className="font-bold">{gymUsers?.name}</p>
                    </div>
                    <div className="mr-10">
                      <p className="text-gray-500">Position</p>
                      <p className="font-bold">{gymUsers?.role_tag}</p>
                    </div>
                    <div className="mr-10">
                      <p className="text-gray-500">Email</p>
                      <p className="font-bold">{gymUsers?.email}</p>
                    </div>
                  </div>
                  <div className="row-3 flex items-center">
                    <button
                      onClick={toggleEditProfileVisibility}
                      className="text-black-900 border-none bg-gray-300 hover:bg-purple-300 rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6"
                    >
                      Edit Profile
                    </button>
                    {role === 2 && (
                      <button
                        onClick={toggleAddAccessVisibility}
                        className="text-black-900 border-none bg-gray-300 hover:bg-purple-300 rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6"
                      >
                        Add access
                      </button>
                    )}
                    {!appInfo?.redprint && (
                      <>
                        <button
                          onClick={toggleResetPasswordVisibility}
                          className="text-black-900 border-none bg-gray-300 hover:bg-purple-300 rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6"
                        >
                          Password reset
                        </button>
                        <button
                          onClick={handleLogout}
                          className="text-red-900 border-none bg-red-300 hover:bg-red-600 rounded-lg font-medium text-sm text-sm px-5 py-1.5 me-2 mb-2 mr-6"
                        >
                          Log out
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex-grow">
                  {(role === 2 || (isVisitor && gymUsers?.role_id === 2)) && (
                    <GymMaintainence
                      orgId={appInfo?.org?.org_id}
                      orgInfo={appInfo?.org?.org_info}
                      gyms={gyms}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          {(role === 2 || (isVisitor && gymUsers?.role_id === 2)) && (
            <div className="flex-grow max-w-[864px]">
              <PeerAccessInfo gyms={gyms} org={appInfo?.org} />
            </div>
          )}

          {role === 1 && !isVisitor && (
            <div className="flex-grow max-w-[864px]">
              <SettingsPage user={user} onOrgClick={handleOrgClick} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const settingsModule = {
  routeProps: {
    path: "/settings",
    element: <Settings />,
  },
  name: "Settings",
};

export default settingsModule;
