import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App";
import { getData } from "../../common/utils";

const VideoPlayer = ({ videoKey, thumbnailKey }) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [thumbnailData, setThumbnailData] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const { appInfo } = useContext(AppContext);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const response = await getData(
          `exercise/video/thumbnail/${thumbnailKey}`,
          appInfo.token
        );
        console.log("thumbnail Response data:", response);

        if (response.Imagedata) {
          setThumbnailData(response.Imagedata);
        }
      } catch (error) {
        console.error("Error fetching thumbnail data", error);
      }
    };

    fetchVideoUrl();
  }, [thumbnailKey, appInfo.token, videoKey]);

  const handleThumbnailClick = async () => {
    // Implementation depends on how you plan to show the video
    const response = await getData(`exercise/video/${videoKey}`, appInfo.token);
    setVideoUrl(response.url);
    console.log("Video URL:", response.url);
    setShowVideo(true);
  };

  return (
    <div className="rounded-t-md overflow-hidden z-8">
      {thumbnailData ? (
        <img
          src={`data:image/jpeg;base64,${thumbnailData}`}
          alt="Thumbnail"
          onClick={handleThumbnailClick}
          className="w-full h-full object-cover"
        />
      ) : (
        <div
          style={{
            width: "360px",
            height: "180px",
          }}
        >
          <p>Loading thumbnail...</p>
        </div>
      )}

      {showVideo && videoUrl && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
          }}
        >
          <video
            controls
            style={{
              width: "80%",
              height: "80%",
              zIndex: "10",
              position: "absolute",
            }}
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button
            onClick={() => setShowVideo(false)}
            style={{
              position: "absolute",
              top: 20,
              right: 20,
              fontSize: "24px",
              color: "white",
            }}
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
